import './PictureBox.css';


const RMModal = (props) => {


    return (
        <div>
        <h3 className='sDesc center' style={{ marginTop:'3%', paddingBottom:"20px", color: `${props.pictureFontColor}`, textShadow:"1px 0px 100px black"}}>
            <b style={{ borderWidth: "25px", padding:"15px", borderRadius: "5px"}}>&nbsp;{props.sDesc}&nbsp;</b></h3>
            
            <div style={{padding:'6%', marginTop:'-8%'}}>
                <pre style={{fontSize:'20px',padding:'6px', backgroundColor:"white" , color: `${props.pictureFontColor}`}}>
                    {`${props.lDesc}`}
                </pre>
            </div>
        </div>
    );
};

export default RMModal;