import React, { useState, useEffect } from "react";
import "./LineGraph.css"
// chart imports
import { Chart, ChartTitle, ChartSeries, ChartSeriesItem, ChartCategoryAxis, ChartCategoryAxisItem, ChartLegend } from '@progress/kendo-react-charts';
import 'hammerjs';

// helpers
import { toMonthName } from "../../../utils/helpers";

export const LineGraph = (props) => {

    const [graphColumns, setGraphColumns] = useState([]);
    const [graphXAxis, setGraphXAxis] = useState([]);

    const xAxis = [];

    const columns = [
        // Leakage chart [0]
        {
            name: "Leakage Identified",
            data: []
        }, {
            name: "Leakage Saved",
            data: []
        }];


    let setGraphData = () => {
        props.graphData.map((dataPoint) => {

            // Daily Earned vs Actual                                      
            if (dataPoint.Title === "Daily Earned Vs Actual") {
                if (dataPoint.Legend === "Money Save/Loss") {          // first line of graph
                    columns[0].data.push(dataPoint.Nr)
                    xAxis.push(toMonthName(dataPoint.Month, dataPoint.Year))
                } else {                                              //second line of graph
                    columns[1].data.push(dataPoint.Nr)
                }
                setGraphColumns(columns)
            }
            // $ Saved by Leakage Observations
            // if(dataPoint.Title == "$ Saved by Leakage Observations"){
            if (dataPoint.Title === "Dollars Saved Through Observation") {
                // console.log(dataPoint)
                if (dataPoint.Legend === "Money Save/Loss") {          // first line of graph
                    columns[0].data.push(dataPoint.Nr)
                    xAxis.push(toMonthName(dataPoint.Month, dataPoint.Year))
                } else {                                              //second line of graph
                    columns[1].data.push(dataPoint.Nr)
                }

                setGraphColumns(columns)
            }

        })

        // console.log(columns)
        // console.log(xAxis)

        setGraphXAxis(xAxis)
    }

    useEffect(() => {
        setGraphData()
    }, []);

    return (
        <div className="col-6">
            <div className="k-card">
                <Chart style={{
                    height: 350,
                }}>
                    {/* {console.log(xAxis)} */}
                    <ChartTitle text={props.graphData[0]?.Title} />
                    <ChartLegend position="top" />
                    <ChartCategoryAxis>
                        <ChartCategoryAxisItem
                            labels={{
                                rotation: "auto",
                            }} 
                            categories={graphXAxis}
                            title={{
                                text: 'Months'
                            }}
                        />
                    </ChartCategoryAxis>

                    <ChartSeries>
                        {/* Sample Data */}
                        {/* <ChartSeriesItem type="line" data={[123, 276, 310, 212, 240, 156, 98]} />
                        <ChartSeriesItem type="line" data={[165, 210, 287, 144, 190, 167, 212]} />
                        <ChartSeriesItem type="line" data={[56, 140, 195, 46, 123, 78, 95]} /> */}
                        {
                            graphColumns.map((item, idx) => <ChartSeriesItem key={idx} type="line"
                                tooltip={{
                                    visible: true
                                }}
                                data={item.data}
                                name={item.name}
                            />)
                        }
                    </ChartSeries>
                </Chart>
            </div>
        </div>
    )
}
