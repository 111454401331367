// import {get} from "../utils/api";
import {get} from "./RestClient";
// import RestClient from "../utils/RestClient";



let apiUrl = "qb/GetPPItems/1003/0";
let myPartAPI = "qb/getpwritems/";
let seeProgAPI = "userinformation/getgraphdata/";

export const loadData = async() => {
    return new Promise(function (resolve, reject) {
        get(apiUrl)
        .then(function (response){
            resolve(response.data)
        })
        .catch(function (error) {
           reject(error);
        })
    });
};

export const myPart = async(empCode, Themeid) => {
    return new Promise(function (resolve, reject) {

        // get(myPartAPI + empCode )
        get(myPartAPI + empCode + "/" + Themeid)
        .then(function (response){
            // console.log(response.data);
            resolve(response.data)
        })
        .catch(function (error) {
           reject(error);
        })
    });
};
export const seeProg = async(empCode, Themeid) => {
    return new Promise(function (resolve, reject) {

        // get(seeProgAPI + empCode)
        get(seeProgAPI + empCode + "/" + Themeid)

        .then(function (response){
            resolve(response.data)
        })
        .catch(function (error) {
           reject(error);
        })
    });
};