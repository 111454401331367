import ReactPlayer from 'react-player'
import './PictureBox.css';

const VModle = (props) => {
    
    let modleStyle ={
        display: 'block',
        backgroundColor: 'rgba(0,0,0,0)',
    }
    



    return (
        <div className="videoDiv center"style={modleStyle}>
            <div className="modal-content center" id="videoWindow" style={{width:'68%',  marginTop:'2%'}}>
                <div className="py-3 py-lg-5 container" style={{marginTop: '-27px' , marginBottom: '-38px'}}>
                    <ReactPlayer url={props.videoUrl} controls style={{all:'unset'}}/>
                </div>
            </div>
        </div>
    );
    
}

export default VModle;