import React, { useState, useEffect } from "react";
import { TextField, Button, InputAdornment, IconButton } from "@material-ui/core";
import "./Login.css";
import { Visibility as ShowIcon, VisibilityOff as HideIcon } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import { getLog, postLog } from '../utils/RestClient';


export default function Login() {

	const [loading, setLoading] = useState(false);
	const [activeForm, setActiveForm] = useState("login");
	function goToLoginForm() { setActiveForm("login"); }
	function goToForgotPasswordForm() { setActiveForm("forgot-password"); }
	function goToResetPasswordForm() { setActiveForm("reset-password"); }
	function goToCreateAccountForm() { setActiveForm("create-account"); }

	if (loading) {
		return <h2 className="load">Loading...</h2>;
	}

	function getLogActiveForm() {
		switch (activeForm) {
			case "login":
				return (
					<LoginForm

						onForgotClick={goToForgotPasswordForm}
						onCreateClick={goToCreateAccountForm}
					/>
				)
			case "forgot-password":
				return (
					<ForgotPasswordForm

						onBackClick={goToLoginForm}
						onResetClick={goToResetPasswordForm}

					/>
				)
			case "reset-password":
				return (
					<ResetPasswordForm

						onBackClick={goToForgotPasswordForm}
						goToLoginForm={goToLoginForm} />
				)
			default:
				return (<></>)
		}
	}


	return (
		<div className="login">
			<div className="login-form">
				{getLogActiveForm()}
			</div>

			<div className="login-splash">
				<div className="login-splash-content">
					<h1>Welcome to<br />Painted Picture<b><br />2026</b></h1>

				</div>
			</div>

		</div>
	)





	/*****************Modals loading**********************/

	// Login Form Modal
	function LoginForm(props) {

		let navigate = useNavigate();
		const [showPassword, setShowPassword] = useState(false);
		const [email, setEmail] = useState('');
		const [password, setPassword] = useState('');


		let handleEmailChange = e => {
			// setEmail(e.targetLog.value.trim())
			setEmail(e.target.value.trim())
			// console.log(e.target.value.trim())
		};
		let handlePasswordChange = e => {
			// setPassword(e.targetLog.value.trim())
			setPassword(e.target.value.trim())
		};

		let handleLogin = async type => {
			try {
				if (email === "" || password === "")
					throw new Error("All fields are required!")

				loadMainData(type);

			}
			catch (error) {
				(error.message === "Request failed with status code 400")
					? alert("Wrong email or password!")
					: alert(error)
			}
		};

		let loadMainData = async () => {
			let res = {};

			// await getLog(`UserInformation/CheckUser/${email}/${password}`)

			await postLog(`UserInformation/CheckUser`, {

				Email: email,
				Password: password,

				// DeviceName: "asd",
				// AppName: "PaintedPicture",
				// Platform: "Windows"
			})
				.then(result => {
					setLoading(true);

					if (result.Status && result.Data) {
						res = result;
					} else {
						setLoading(false);
					}
				})
				.catch(ex => {
					console.log('catch ex' + ex);
					setLoading(false);
				});

			console.log('status', res.Status)
			// console.log('status data', res.Data)

			if (res.Status && res.Data) {

				let personInfo = res.Data;
				// console.log(personInfo)

				navigate("/PaintedPicture", { state: personInfo });

			}

			setLoading(false);
		};


		return (
			<div className="login-form-content">
				<h3 style={{ paddingBottom: '10px' }}>Login</h3>

				<form>
					<div className="input-container">
						<TextField
							variant="outlined"
							label="Email"
							fullWidth
							// defaultValue={props.email}
							onChange={(event) => handleEmailChange(event)

							}
						/>
					</div>
					<div className="input-container">
						<TextField
							id="password-textfield"
							type={showPassword ? "text" : "password"}
							variant="outlined"
							label="Password"
							fullWidth
							// defaultValue={password}
							onChange={(event) => handlePasswordChange(event)}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											onClick={() => setShowPassword(!showPassword)}>
											{showPassword ? <HideIcon /> : <ShowIcon />}
										</IconButton>
									</InputAdornment>
								)
							}}
							onKeyPress={(event) => {
								if (event.key === "Enter")
									// handleLoginClick(event);
									handleLogin();
							}}
						/>
					</div>
				</form>

				<div className="flex-container-horizontal" style={{ marginTop: '25px' }}>
					<div style={{ width: "50%" }}>
						<div className="input-container" id='forgot-password'>
							<Button
								size="small"
								color="primary"
								// onClick={props.onForgotClick}>
								onClick={props.onForgotClick}>
								Forgot Password
							</Button>
						</div>
					</div>
					<div style={{ width: "50%", float: "right", textAlign: "right" }}>
						<div className="input-container" style={{ marginTop: '-35%' }} id='LoginButton'>
							<Button
								variant="contained"
								color="primary"
								onClick={() =>

									handleLogin(1)

								}>
								Login
							</Button>
						</div>
					</div>
				</div>
			</div>
		)
	}





	// ForgotPassword Form Modal
	function ForgotPasswordForm(props) {
		// const [isLoading, setIsLoading] = useState(false);
		const [fgemail, setFGEmail] = useState('');

		let generateResetCode = async (email) => {

			await getLog(`UserInformation/ForgotPassword//${email}`)

				.then((result) => {

					if (result == "OK") {
						// setIsLoading(false);
						console.log(result)
					}
					else {
						// setIsLoading(false);
					}
				})
				.then((result) => {
					// redirects to password reset page
					props.onResetClick();
				})
				.catch(ex => {
					console.log('CLG Error:' + ex)
					// setIsLoading(false);
				});
		}

		let handleEmailChangePasswordForm = e => {
			// setFGEmail(e.targetLog.value.trim())
			setFGEmail(e.target.value.trim())
			// console.log(fgemail)
		};

		async function handlePasswordRecoveryClick() {
			try {
				// setIsLoading(true);	// Show loading animation
				if (fgemail === "")
					throw new Error("Email is a required field!")

				await generateResetCode(fgemail);
				alert("If there is an account associated with this email, you will receive a reset code.");
				// props.onResetClick();	// Redirect to reset password form
			}
			catch (error) {
				// setIsLoading(false);	// Clear loading animation
				alert(error);
			}
		}

		return (
			<div className="login-form-content">
				{
					// isLoading
					// 	? /*<Loader/>*/'Loading'
					// 	: (
					<>
						<h3 style={{ paddingBottom: '8px', fontSize: '31px' }}>Forgot your password?</h3>
						<h6 style={{ paddingBottom: '28px', fontSize: '17px' }}>Receive a Reset Code in your email</h6>
						<form>
							<div className="input-container">
								<TextField
									variant="outlined"
									label="Email"
									fullWidth
									// defaultValue={props.email}
									onChange={(e) => handleEmailChangePasswordForm(e)}
								/>
							</div>
						</form>

						<div className="flex-container-horizontal">
							<div className="flex-container-left">
								<div className="input-container" id="backButton">
									<Button
										size="small"
										color="primary"
										onClick={props.onBackClick}>
										Back
									</Button>
								</div>
							</div>

							<div className="flex-container-right" style={{ marginLeft: '240px', marginTop: '-60px' }}>
								<div className="input-container">
									<Button
										id="SubmitButton"
										variant="contained"
										color="primary"
										onClick={() => handlePasswordRecoveryClick()}>
										Submit
									</Button>
								</div>
							</div>
						</div>

						<div className="input-container" style={{ paddingTop: '20px' }}>
							<Button
								size="small"
								color="primary"
								onClick={() => props.onResetClick()}>
								I have a reset code
							</Button>
						</div>
					</>
					// )
				}
			</div>
		)
	}



	//ResetPasswordForm
	function ResetPasswordForm(props) {

		const [isLoading, setIsLoading] = useState(false);

		const [rpfEmail, setRPFEmail] = useState("");

		const [resetCode, setResetCode] = useState("");

		const [showPassword, setShowPassword] = useState(false);
		const [showRetypePassword, setShowRetypePassword] = useState(false);
		const [newPassword1, setNewPassword1] = useState('');
		const [newPassword2, setNewPassword2] = useState('');


		function handleResetCodeTextfieldChange(event) {
			// setResetCode(event.targetLog.value);
			setResetCode(event.target.value);
		}

		function handlePasswordTextfieldChange(event) {
			// setNewPassword1(event.targetLog.value);
			setNewPassword1(event.target.value);
			// console.log(newPassword1)
		}

		function handleRetypePasswordTextfieldChange(event) {
			// setNewPassword2(event.targetLog.value);
			setNewPassword2(event.target.value);
			// console.log(newPassword2)
		}

		let handleEmailResetPasswordForm = (e) => {
			// setRPFEmail(e.targetLog.value)
			setRPFEmail(e.target.value)
			// console.log(rpfEmail)
		};

		let verifyResetCode = async () => {

			if (resetCode.trim().length === 0) {
				alert('Not valid reset code!')
				return;
			}

			// await getLog(`UserInformation/IsValidResetCode/${rpfEmail}/${resetCode}`).then((result) => {

			await postLog(`UserInformation/IsValidResetCode`, {
				email: rpfEmail,
				resetCode: resetCode,
				password: newPassword1
			}).then((result) => {

				console.log(result);

				//console.log(result);
				if (result) {
					console.log("reset code is good + valid")
					handleSavePassword()
				}
				else {
					console.log("result was not ok for resetcode checker")
					alert('Reset Code was not valid')
				}
			})
				.catch(ex => {
					console.log(ex)
				});
		}

		let handleSavePassword = () => {


			if (!rpfEmail || rpfEmail.length === 0) {
				alert("Email is a mandatory field !");
				return;
			}

			if (newPassword1.length === 0) {
				alert('Please type a password !');
				return;
			}

			// if (newPassword2.length === 0) {
			// 	alert('Please retype the password !');
			// 	return;
			// }

			// if (newPassword1 !== newPassword2) {
			// 	alert('The password fields do not match !');
			// 	return;
			// }

			try {
				savePassword();
			}
			catch (ex) {
				console.log('error occurred' + ex);
			}
		}

		let savePassword = async () => {

			let res = '';


			await postLog('UserInformation', { Email: rpfEmail, Password: newPassword1 })

				.then((result) => {

					// console.log(rpfEmail);
					// console.log(newPassword1);

					res = result;


					if (res.Message == "OK") {
						setIsLoading(false);
						props.goToLoginForm();
						alert("Password successfully changed!!");
						props.goToLoginForm();
					}
					else {
						alert("Information is not correct")
						props.goToLoginForm();
						console.log("Password did not save ok " + res)
					}


				})
				.catch(ex => {
					console.log('catch error' + ex)
				});

		}

		async function handleResetPasswordClick() {
			try {
				setIsLoading(true);	// Show loading animation

				// Form checking
				if (resetCode === "" || newPassword1 === "" /*|| newPassword2 === ""*/) {
					throw new Error("All fields are required!")
				}

				await verifyResetCode(rpfEmail, resetCode);

			}
			catch (error) {
				setIsLoading(false);	// Clear loading animation
				alert(error);
			}
		}

		return (
			<div className="login-form-content">
				{
					// isLoading
					// ? /*<Loader/>*/'Loading...'
					// : (
					<>
						<h3>Reset Password</h3>
						<form>
							<div className="input-container">
								<TextField
									variant="outlined"
									label="Email"
									fullWidth
									// defaultValue={props.email}
									onChange={(e) => handleEmailResetPasswordForm(e)} />
							</div>
							<div className="input-container" style={{ paddingBottom: "30px" }}>
								<TextField
									variant="outlined"
									label="Reset Code"
									fullWidth
									defaultValue={resetCode}
									onChange={handleResetCodeTextfieldChange} />
							</div>
							<div className="input-container">
								<TextField
									type={showPassword ? "text" : "password"}
									variant="outlined"
									label="New Password"
									fullWidth
									// defaultValue={password}
									onChange={(e) => handlePasswordTextfieldChange(e)}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													onClick={() => setShowPassword(!showPassword)}>
													{showPassword ? <HideIcon /> : <ShowIcon />}
												</IconButton>
											</InputAdornment>
										)
									}} />
							</div>
							{/* <div className="input-container">
								<TextField
									type={showRetypePassword ? "text" : "password"}
									variant="outlined"
									label="Retype New Password"
									fullWidth
									// defaultValue={retypePassword}
									onChange={handleRetypePasswordTextfieldChange}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													onClick={() => setShowRetypePassword(!showRetypePassword)}>
													{showRetypePassword ? <HideIcon /> : <ShowIcon />}
												</IconButton>
											</InputAdornment>
										)
									}} />
							</div> */}
						</form>

						<div className="flex-container-horizontal">
							<div className="flex-container-left">
								<div className="input-container">
									<Button
										size="small"
										color="primary"
										onClick={props.onBackClick}>
										Back
									</Button>
									<Button
										id='ResetButton'
										style={{ marginLeft: '53%' }}
										variant="contained"
										color="primary"
										onClick={handleResetPasswordClick}>
										Reset
									</Button>
								</div>

							</div>
						</div>
					</>
					// )
				}
			</div>
		)
	}
}