import "./PictureBox.css";
import React, { useState, useEffect } from "react";

import "hammerjs";
// Graph imports
import { BarGraph } from "../Graphs/BarGraph/BarGraph";
import { StackedBarGraph } from "../Graphs/BarGraph/StackedBarGraph";
import { PieChart } from "../Graphs/PieChart/PieChart";
import { LineGraph } from "../Graphs/LineGraph/LineGraph";
import { ArcGaugeGraph } from "../Graphs/ArcGaugeGraph/ArcGaugeGraph";

import CircularProgress from "@mui/material/CircularProgress";
import { seeProg } from "../../utils/fetcher";

//   -------------------------
const SPModal = (props) => {
  const [progData, setProgData] = useState([]);
  const [loading, setLoading] = useState(false);

  // From Leakage To Upsurge
  const [leadingArray, setleadingArray] = useState([]);
  const [laggingArray, setLaggingArray] = useState([]);
  let uniqueLeadingChartsTest = {};
  let uniqueLaggingChartsTest = {};

  // OSCOB Culture
  const [activeCadence, setActiveCadence] = useState([]);
  const [feedback, setFeedback] = useState([]);
  const [oscob, setOscob] = useState([]);

  //Recruitment & Development
  const [pieChartData, setPieChartData] = useState([]);
  const [pieChartData2, setPieChartData2] = useState([]);
  const [stackedBarChartData, setStackedBarChartData] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      let res = await seeProg(props.getPersonInfo, props.id);
      setProgData(res.Data);
      setLoading(false);
    };

    fetchPosts();
  }, []);

  let uniqLeadingChartsFunc = () => {
    let leadingArr = progData.filter((item) => item.Section === "Leading");

    // sort each graph point into array
    leadingArr.forEach((obj) => {
      let doesExists = uniqueLeadingChartsTest[obj.Title];
      let tempLegPerc = obj.Legend.replace("Per", "%");
      obj.Legend = tempLegPerc;

      if (doesExists !== undefined) {
        doesExists.push(obj);
        uniqueLeadingChartsTest[obj.Title] = doesExists;
      } else {
        uniqueLeadingChartsTest[obj.Title] = [obj];
      }
    });

    setleadingArray(
      Object.values(uniqueLeadingChartsTest).sort(function (a, b) {
        return a[0].OrderOnGraphs - b[0].OrderOnGraphs;
      })
    );
  };

  let uniqLaggingChartsFunc = () => {
    let laggingArrayTest = progData.filter((item) => item.Section === "Lagging");

    laggingArrayTest.forEach((obj) => {
      let doesExists = uniqueLaggingChartsTest[obj.Title];
      let tempLegPerc = obj.Legend.replace("Per", "%");
      obj.Legend = tempLegPerc;

      if (doesExists !== undefined) {
        doesExists.push(obj);
        uniqueLaggingChartsTest[obj.Title] = doesExists;
      } else {
        uniqueLaggingChartsTest[obj.Title] = [obj];
      }
    });

    setLaggingArray(
      Object.values(uniqueLaggingChartsTest).sort(function (a, b) {
        return a[0].OrderOnGraphs - b[0].OrderOnGraphs;
      })
    );
  };

  let oscobTitleFilterer = () => {
    let activeCadenceLocal = progData.filter(
      (item) => item.Title === "Active Cadence"
    );
    let feedbackLocal = progData.filter(
      (item) => item.Title === "Feedback 6 Weeks"
    );
    let oscobLocal = progData.filter((item) => item.Title === "OSCOB 6 Weeks");

    setActiveCadence(activeCadenceLocal);
    setFeedback(feedbackLocal);
    setOscob(oscobLocal);
  };

  useEffect(() => {
    // LEAKAGE
    if (props.id === 1) {
      // leading
      uniqLeadingChartsFunc();
      // lagging
      uniqLaggingChartsFunc();

      // OSCOB
    } else if (props.id === 2) {
      // filters by title
      oscobTitleFilterer();
    } else if (props.id === 3) {
      // filters by pie chart data
      let tempPieData = progData.filter(
        (item) => item.Title === "Percentage as Referals"
      );
      setPieChartData(tempPieData);

      // filters by double bar chart data
      let tempStackedBarChart = progData.filter(
        (item) => item.Title === "Number of Coaching Requests/month"
      );

      const groupByCategory = tempStackedBarChart.reduce((group, dataPlot) => {
        const { RefDate } = dataPlot;
        group[RefDate] = group[RefDate] ?? [];
        group[RefDate].push(dataPlot);
        return group;
      }, {});

      setStackedBarChartData(Object.entries(groupByCategory));

      let tempPieChart2 = progData.filter(
        (item) => item.Title === "Number of active green sheets %"
      );
      setPieChartData2(tempPieChart2);
    }
  }, [progData]);

  if (loading) {
    return (
      <h2 className="load">
        <CircularProgress />
      </h2>
    );
  }

  return leadingArray.length > 0 ? (
    <>
      {/* LEADING */}
      <div className="chartDiv container-fluid">
        {/* section title */}
        <h1 className="center">
          <b>{leadingArray[0][0].Section}</b>
        </h1>
        <div className="row mb-3">
          {/* Leakage Actions Graph */}
          <BarGraph graphData={leadingArray[0]} />
          {/* Change Tracker Graph */}
          <BarGraph graphData={leadingArray[1]} />
          {/* Historical Production Added */}
          <BarGraph graphData={leadingArray[2]} />
          {/* Productions Used In Estimated */}
          <BarGraph graphData={leadingArray[3]} />
          {/* Bids Reviewed By Super (%) Graph */}
          <PieChart graphData={leadingArray[4]} />
        </div>

        {/* LAGGING */}
        <h1 className="center">
          <b>{laggingArray[0][0].Section}</b>
        </h1>
        <div className="row mb-3">
          {/* TEMP MOVE THIS TO LEADING */}

          {laggingArray[0] ? <LineGraph graphData={laggingArray[0]} /> : ""}
          {/* Dollars Saved Through Observation */}
          {laggingArray[1] ? <LineGraph graphData={laggingArray[1]} /> : ""}
        </div>
      </div>
    </>
  ) : progData.length > 0 && props.id === 2 ? (
    // OSCOB Culture
    <div className="chartDiv container-fluid" style={{ marginTop: "15px" }}>
      <div className="row center">
        {/* OSCOB Title */}
        <BarGraph graphData={oscob} />

        {/*activeCadence Title */}
        <div className="row center" style={{ margin: "50px" }}>
          <div className="k-card" style={{ width: "50%" }}>
            <h4
              className="center"
              style={{ marginBottom: "15px", marginTop: "10px" }}
            >
              {activeCadence[0].Title}
            </h4>
            <ArcGaugeGraph graphData={activeCadence} />
          </div>
        </div>

        {/*feedback Title */}
        <BarGraph graphData={feedback} />
      </div>
    </div>
  ) : pieChartData.length > 0 && props.id === 3 ? (
    // Recruitment and Development
    <div className="chartDiv container-fluid" style={{ marginTop: "15px" }}>
      <div className="row center">
        {/* ------------------Recruitment and Development Title ------------------*/}
        {/*Pie Chart*/}
        <div className="row center" style={{ margin: "50px" }}>
          <div className="k-card" style={{ width: "50%" }}>
            <h4
              className="center"
              style={{ marginBottom: "15px", marginTop: "10px" }}
            >
              {pieChartData[0].Title}
            </h4>
            <PieChart pieData={pieChartData} id={props.id} />
          </div>
        </div>

        {/* Number of Coaching Requests/month */}
        <div className="row center" style={{ margin: "50px" }}>
          <div className="k-card" style={{ width: "50%" }}>
            <h4
              className="center"
              style={{ marginBottom: "15px", marginTop: "10px", textAlign:"center" }}
            >
              {stackedBarChartData[0][1][0].Title}
            </h4>
            <StackedBarGraph graphData={stackedBarChartData} />
          </div>
        </div>

        {/*Active Green Sheets*/}
        <div className="row center" style={{ margin: "50px" }}>
          <div className="k-card" style={{ width: "50%" }}>
            <h4
              className="center"
              style={{ marginBottom: "15px", marginTop: "10px" }}
            >
              {pieChartData2[0].Title}
            </h4>
            <PieChart pieData={pieChartData2} id={props.id} />
          </div>
        </div>
      </div>
    </div>
  ) : (
    setLoading(true)
  );
};

export default SPModal;
