import React, {useState, useEffect} from "react";

import {ArcGauge} from '@progress/kendo-react-gauges';


export const ArcGaugeGraph = (props) => {

  // sets color for value range
  const colorSetter = [
    {
        from: 0,
        to: 30,
        color: 'red'
    }, {
        from: 30,
        to: 70,
        color: 'yellow'
    }, {
        from: 70,
        color: 'green'
    }
  ];

  const [value, setValue] = useState(0);

  useEffect(()=>{
    setValue(props.graphData[0].Nr);
  },[])

  // percentage number under the arc
  const arcCenterRenderer = (value, colorSetter) => {
    return (<h3>{value}%</h3>);
  };

  return (
    <div className="center">
      <ArcGauge
        style={{width:"500px"}}
        value= {value} 
        colors={colorSetter} 
        arcCenterRender={arcCenterRenderer} 
        scale={{
          labels: { color: 'black', visible: true },
          majorTicks: { visible: true, color: "black" },
          rangeSize: 0,
          reverse: false,
        }}
      />
    </div>
  )
}
