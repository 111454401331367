import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartTitle,
  ChartLegend,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";
import { toMonthName } from "../../../utils/helpers";

import "hammerjs";

export const StackedBarGraph = (props) => {
  let yes = [];
  let no = [];
  let date = [];

  //Array Populator
  props.graphData.map((data) => {
    // NO
    no.push(data[1][0].Nr);
    //YES
    yes.push(data[1][1].Nr);
    //X Axis dates
    date.push(toMonthName(data[1][1].Month, data[1][1].Year));
  });

  return (
    <Chart>
      <ChartCategoryAxis>
        <ChartCategoryAxisItem
          title={{
            text: "Manager Acknowledged/Date Created",
          }}
          categories={date}
          startAngle={45}
        />
      </ChartCategoryAxis>

      <ChartValueAxis>
        <ChartValueAxisItem
          title={{
            text: "Number of Coaching opportunities",
          }}
        />
      </ChartValueAxis>

      <ChartSeries>
        {/* Yes */}
        <ChartSeriesItem
          // tooltip={{
          //   visible: true,
          // }}
          type="column"
          stack={true}
          data={yes}
          name="Yes"
        />
        {/* No */}
        <ChartSeriesItem
          // tooltip={{
          //   visible: true,
          // }}
          type="column"
          stack={true}
          data={no}
          name="No"
        />
      </ChartSeries>
    </Chart>
  );
};
