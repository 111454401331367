import axios from 'axios'

const baseUrl = 'https://tsmiscwebapi.azurewebsites.net/api/';
const getUrl = url => `${baseUrl}${url}`;
const header = {
    headers: { 'Content-Type': 'application/problem+json; charset=utf-8' }
}

//General GET
export async function get(url) {

    return await axios.get(
        getUrl(url),
    );
}

//General POST 
export async function post(url, data) {
    // adding header to body
    const body = Object.assign(data, header)
    try {
        return await axios.post(
            getUrl(url),
            body
        );
    }
    catch (error) {
        console.log(error);
        throw error;
    }
}


//POST REQ Login
export async function postLog(url, params) {
    return new Promise(function (resolve, reject) {
        // Detect platform and device information
        const userAgent = navigator.userAgent;
        let os = 'Unknown';
        let deviceName = 'Desktop';  // Default to Desktop

        if (userAgent.includes('Win')) {
            os = 'Windows';
        } else if (userAgent.includes('Mac')) {
            os = 'MacOS';
        } else if (userAgent.includes('Android')) {
            os = 'Android';
        } else if (userAgent.includes('Linux')) {
            os = 'Linux';
        } else if (userAgent.includes('iPhone') || userAgent.includes('iPad')) {
            os = 'iOS';
        }

        if (/mobile/i.test(userAgent)) {
            deviceName = 'Mobile';
        } else if (/tablet/i.test(userAgent)) {
            deviceName = 'Tablet';
        }

        // Add DeviceName and Platform to the params
        const updatedParams = {
            ...params,
            DeviceName: deviceName,
            Platform: os,
            AppName: "PaintedPicture"
        };

        // Perform the POST request with the updated params
        console.log(updatedParams);

        axios.post(getUrl(url), updatedParams)
            .then(function (response) {
                console.log(response);
                resolve(response.data);
            })
            .catch((error) => {
                console.log('error from the rest client', error);
                reject(error);
            });
    });
}

//GET REQ Login
export async function getLog(url) {
    return new Promise(function (resolve, reject) {
        axios.get(getUrl(url))

            .then(function (response) {
                // console.log(response.data);
                resolve(response.data)
            })
            .catch((error) => {
                console.log('error from the rest client', error)
                alert("Invalid Login information")

            })
    })
}




