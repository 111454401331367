import React, {useState, useEffect} from "react";
import './PictureBox.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {myPart} from '../../utils/fetcher'
import CircularProgress from '@mui/material/CircularProgress';

const MPModal = (props) => {
   
    const [myPartData, setmMyPartData] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        const fetchPosts = async () =>{
            setLoading(true);
                let res = await myPart(props.getPersonInfo, props.id);
                setmMyPartData(res.Data);
            setLoading(false);
        }
        fetchPosts();
      }, []);

      if(loading) {return <h2 className="load"><CircularProgress /></h2>;}
      

    return (
        
        <div className="myPartDiv" style={{border:'black'}}>
            <br/>
            {myPartData.length > 0 ? (
            <>
                {myPartData.map((card)=>{
                    return(
                    <Card className ='cardContBox' key ={card.Id} component="div" variant="outlined" style={{ width: '70%', marginLeft:"15%"}}>
                        {myPartData ? (
                            <>
                                <CardContent className="cardCont" style={{padding:'6%'}}>
                                    <Typography className ='cardContText center' variant="h4" sx={{ fontSize: 28 }} component="div" color="text.secondary" gutterBottom>
                                       <b> {card.PartName} </b>
                                    </Typography>
                                    <Typography className ='cardContText center' variant="h6" component="div">
                                    {card.DescriptionOfPart}
                                    </Typography>
                                        <br /> <br />
                                    <Typography className ='cardContText 'sx={{ mb: 1.5 }} style={{paddingBottom:'0'}}/*color="text.secondary"*/>
                                    {`${card.TargetName}: ${card.TargetNumber}`}
                                    </Typography>
                                        
                                    <Typography className ='cardContText 'sx={{ mb: 1.5 }} style={{}}>
                                    {`${card.ReportingName}: ${card.ReportingNumber}`}
                                        
                                    </Typography>
                                </CardContent>
                            </> 
                             ): <CircularProgress />} 
                    </Card>
                    )
                    
                })}
            </>
            // ) : <CircularProgress style={{width:"60px", marginLeft:"48%", marginTop:"20%"}}/>}
            ) : ""}
        </div>
    );
    
    
};

export default MPModal;