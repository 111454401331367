import './App.css';
import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

//Nav Pages
import PictureBox from './apps/Components/PictureBox';
import Login from "./Login/Login";




function App() {
   

  return (
    <Router>
      <Routes>
        <Route path='/' element={<Login/>}/>
        <Route path='PaintedPicture' element={<PictureBox />}/>
      </Routes>
    </Router>
  );
}

export default App;
