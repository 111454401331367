import React, { useState, useEffect } from "react";
import './PictureBox.css';
import DModal from "./DModle"
import {loadData} from '../../utils/fetcher'
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";


const PictureBox = () => {

  const [dmodal, setDModal] = useState(false);
  const [tempData, setTempData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pictureList, setPictureList] = useState([]);
  let navigate = useNavigate();

  const {state} = useLocation();

  const getData = (img, title, sDesc, longDesc, videoUrl, whoActive, pictureFontColor, Id) => {
    let temData = [img, title, sDesc, longDesc, videoUrl, whoActive, pictureFontColor, Id];
    setTempData([1, ...temData])
    return setDModal(true)
  }

  // disable scrolling when modle active
  if(dmodal) {
    document.body.style.overflow = "hidden"
  } else {
    document.body.style.overflow = ""
  }
  
  
  //fetch pictures from API
  useEffect(() => {
    const fetchPosts = async () =>{
      // if state is null go back to login
      if(state == null){
        navigate("/");
      }

      setLoading(true);
      let res = await loadData();
      setPictureList(res.Data);
      setLoading(false);

    }
    fetchPosts();
  }, []);

  if(loading){
    return <h2 className="load"><CircularProgress /></h2>;
  }
  
  // Toggles block vs hide text view for clicking an image, no need for phone as tested
  const hoverToggler = () => {

    // let qSel = document.querySelector('.box:hover .info-content');

    // if(qSel.style.display === 'none'){
    //    qSel.style.display = 'block'

    // }else if(qSel.style.display === 'block') {
    //   qSel.style.display = 'none'
    // }
  }
  
  return(
    <>
    <section className="box-container">
      <div className="container-fluid">
      <div><h1 id="titlePP" className="center">Painted Picture 2026</h1></div>
        <div className="row center">
          {pictureList.map((pic)=>{
            return(
              <div className="col-md-4 box" onClick={()=>{ hoverToggler() }} key={pic.Id} >
                <img src={`${pic.PictureUrl}`} className="box-img" alt="..." />
                <div className="info-content " style={{display:'none'}} >
                  <h2 className="title center" style ={{color: `${pic.PictureFontColor}`}}> {pic.Title} </h2>
                  { pic.Active ? (<>
                  
                  <div className="description center" style={{marginBottom:'35px'}}>
                    <h5 className="center"style ={{color: `${pic.PictureFontColor}`, fontSize:'17px'}}>{pic.ShortDescription}</h5>
                  </div>
                  
                  
                  <div className="link-container center hover" style={{marginTop:'5%'}}>                    
                    {/* Button 1 Read More */}
                    <button className="btn btn-link" 
                    style={{color: `${pic.PictureFontColor}` }} 
                    onClick={()=>{ 
                      getData(pic.PictureUrl, pic.Title, pic.ShortDescription, pic.LongDescription, pic.VideoUrl, 'rmActive', pic.TextFontColor, pic.Id)
                    }
                    }>Read More </button>      
                      
                    {/* Button 2 Watch Video*/}
                    <button className="btn btn-link" 
                    style={{color: `${pic.PictureFontColor}`}} 
                    onClick={()=> 
                      getData(pic.PictureUrl, pic.Title, pic.ShortDescription, pic.LongDescription, pic.VideoUrl, 'wvActive', pic.TextFontColor, pic.Id)
                    }> Watch Video </button>  

                    {/* Button 3 See Progress*/}
                    <button className="btn btn-link " 
                    style={{color: `${pic.PictureFontColor}`}}
                    onClick={()=> 
                      getData(pic.PictureUrl, pic.Title, pic.ShortDescription, pic.LongDescription, pic.VideoUrl, 'spActive', pic.TextFontColor, pic.Id)
                    }>See Progress </button>                                      
                  </div>

                  <div className="link-container center" style={{marginLeft:"-5.5px"}}>
                    {/* Button 4 My Part*/}
                    <button className="btn btn-link" 
                    style={{color: `${pic.PictureFontColor}`}} 
                    onClick={()=> 
                      getData(pic.PictureUrl, pic.Title, pic.ShortDescription, pic.LongDescription, pic.VideoUrl, 'mpActive', pic.TextFontColor, pic.Id)
                    }> My Part</button>                   
                  </div>
                  </> ) : ''}
                </div>
                
              </div>
            )
          })}
        </div>
      </div>
    </section>

      {dmodal === true ? 
        <DModal 
          img={tempData[1]} 
          title={tempData[2]} 
          sDesc={tempData[3]} 
          lDesc={tempData[4]} 
          videoUrl={tempData[5]} 
          active={tempData[6]}
          textFontColor={tempData[7]}
          id={tempData[8]}
          getPersonInfo={state.EmployeeCode}

        
          hideD={() => setDModal(false)} 
        />
          : 
        ''
        }
    </>
  )
  

}
export default PictureBox;


