import React, { useEffect, useRef, useState } from 'react';
import './PictureBox.css';
import VModal from "./VModle"
import RMModal from "./RMModal"
import SPModal from "./SPModal"
import MPModal from "./MPModal"


const DModle = (props) => {

    const [rmModal, setRmModal] = useState(false);
    const [vModal, setVModal] = useState(false);
    const [progModal, setProgModal] = useState(false);
    const [mypartModal, setMypartModal] = useState(false);

    const [activeColor1, setActiveColor1] = useState('');
    const [activeColor2, setActiveColor2] = useState('');
    const [activeColor3, setActiveColor3] = useState('');
    const [activeColor4, setActiveColor4] = useState('');

    const [activeTextColor1, setActiveTextColor1] = useState('');
    const [activeTextColor2, setActiveTextColor2] = useState('');
    const [activeTextColor3, setActiveTextColor3] = useState('');
    const [activeTextColor4, setActiveTextColor4] = useState('');


    let modleStyle = {
        display: 'block',
        backgroundColor: 'rgba(0,0,0,0.8)',
        overflow: "hidden"
    }
    let modleContentStyle = {
        position: 'relative',
        marginLeft: "15%",
        marginTop: "3%",
        // backgroundImage:`url(${props.img})`,
        backgroundColor: "#f3f3ec",
        // opacity:"20%", 
        height: '84%',
        width: '70%',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
    }
    // checks if user clicks out of the ref(Menu Modal) & will close if true
    // const ref = useRef();
    // useEffect(() => {

    //     const outChecker = (e) => {
    //         if (ref.current && !ref.current.contains(e.target)) {
    //             props.hideD();
    //         }
    //     };
    //     document.addEventListener("click", outChecker);
    //     return () => {
    //         document.removeEventListener("click", outChecker);
    //     }
    // }, [props.hideD]) 

    // initial button press view state
    useEffect(() => {
        switch (props.active) {
            case 'rmActive':
                setActiveColor1('grey');
                setRmModal(true);

                setActiveTextColor1('white');

                break;
            case 'wvActive':
                setActiveColor2('grey');
                setVModal(true);

                setActiveTextColor2('white');

                break;
            case 'spActive':
                setActiveColor3('grey');
                setProgModal(true);

                setActiveTextColor3('white');

                break;
            case 'mpActive':
                setActiveColor4('grey');
                setMypartModal(true);

                setActiveTextColor4('white');

                break;
        }
    }, [])

    let resetButtonVisit = () => {
        setActiveColor1('')
        setActiveColor2('')
        setActiveColor3('')
        setActiveColor4('')

        setActiveTextColor1('')
        setActiveTextColor2('')
        setActiveTextColor3('')
        setActiveTextColor4('')
    }
    let hideModals = () => {
        setRmModal(false);
        setVModal(false);
        setProgModal(false);
        setMypartModal(false);
    }

    return (
        <>
            <div className="modal show" style={modleStyle} >
                <div style={{ height: '100%', width: '100%' }} >
                    <div className="modal-content " style={modleContentStyle} /*ref={ref}*/>
                        <div className="modal-header backdrop" style={{ backgroundColor: 'white' }}>
                            <h5 className="modal-title" style={{ fontWeight: 'bold', fontSize: '200%', marginLeft: 'auto', paddingLeft: '6%' }}>{props.title}</h5>
                            <button type="button" onClick={() => { props.hideD() }} className="btn-close" style={{ float: "right", marginBottom: "10px" }} ></button>
                        </div>
                        <div className="modal-body backdrop">
                            <div className="link-container center" style={{ marginTop: '0%', color: 'white', marginLeft: '0.1px' }}>
                                {/* READ MORE */}
                                <button className='menuButton' id="menuButtonRM"
                                    style={{ fontSize: '140%', marginLeft: 'auto', backgroundColor: `${activeColor1}`, color: `${activeTextColor1}` }}
                                    onClick={() => {
                                        hideModals();
                                        resetButtonVisit()
                                        setRmModal(true);
                                        setActiveColor1("grey")
                                        setActiveTextColor1('white')
                                    }}> Read More
                                </button>
                                {/* WATCH VIDEO */}
                                <button className='menuButton' id="menuButton1"
                                    style={{ fontSize: '140%', marginLeft: 'auto', backgroundColor: `${activeColor2}`, color: `${activeTextColor2}` }}
                                    onClick={() => {
                                        hideModals();
                                        resetButtonVisit()
                                        setVModal(true);
                                        setActiveColor2("grey")
                                        setActiveTextColor2('white')
                                    }}> Watch Video
                                </button>
                                {/* SEE PROGRESS */}
                                <button className='menuButton' id="menuButton2"
                                    style={{ fontSize: '140%', marginLeft: 'auto', marginRight: 'auto', backgroundColor: `${activeColor3}`, color: `${activeTextColor3}` }}
                                    onClick={() => {
                                        hideModals();
                                        resetButtonVisit()
                                        setProgModal(true);
                                        setActiveColor3("grey")
                                        setActiveTextColor3('white')
                                    }}>See Progress
                                </button>
                                {/* MY PART */}
                                <button className='menuButton' id="menuButton3"
                                    style={{ fontSize: '140%', marginRight: 'auto', backgroundColor: `${activeColor4}`, color: `${activeTextColor4}` }}
                                    onClick={() => {
                                        hideModals();
                                        resetButtonVisit()
                                        setMypartModal(true);
                                        setActiveColor4("grey")
                                        setActiveTextColor4('white')
                                    }}>My Part
                                </button>
                            </div>
                            <div style={{ position: "", marginTop: '10px' }}>
                                {rmModal === true ? <RMModal
                                    sDesc={props.sDesc}
                                    lDesc={props.lDesc}
                                    pictureFontColor={props.textFontColor}
                                    {...props}
                                />
                                    :
                                    ''
                                }
                                {vModal === true ? <VModal
                                    videoUrl={props.videoUrl}
                                    pictureFontColor={props.textFontColor}
                                    {...props}
                                />
                                    :
                                    ''
                                }
                                {progModal === true ? <SPModal
                                    pictureFontColor={props.textFontColor}
                                    getPersonInfo={props.getPersonInfo}
                                    {...props}
                                />
                                    :
                                    ''
                                }
                                {mypartModal === true ? <MPModal
                                    pictureFontColor={props.textFontColor}
                                    getPersonInfo={props.getPersonInfo}
                                    {...props}
                                />
                                    :
                                    ''
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DModle;