import React, { useState, useEffect } from "react";

// chart imports
import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
} from "@progress/kendo-react-charts";
import "hammerjs";

export const PieChart = (props) => {
  const [pieData, setPieData] = useState([]);
  const [pieDataPerc, setPieDataPerc] = useState([]);

  useEffect(() => {
    if (props.id === 3) {
      //Reacruitment & Development

      let graphData = props.pieData.filter(
        (item) => !item.Legend.includes("(%)")
      );
      let graphDataPerc = props.pieData.filter((item) =>
        item.Legend.includes("(%)")
      );

      setPieData(graphData);
      setPieDataPerc(graphDataPerc);
    }
  }, []);

  return (
    <div className="k-card">
      <Chart>
        {/* <ChartTitle text={props.pieData[0].Title} /> */}
        <ChartLegend position="bottom" />
        <ChartSeries>
          <ChartSeriesItem
            type="pie"
            data={pieData}
            field="Nr"
            categoryField="Legend"
            overlay={{
              gradient: "roundedBevel",
            }}
            colorField="pie"
            tooltip={{
              visible: true,
            }}
            labels={{
              visible: true,
            }}
          />
        </ChartSeries>
      </Chart>
      {/* Percentages */}
      <div style={{ textAlign: "center" }}>
        {pieData.map((data, i) => (
          <span key={i} style={{ padding: "20px", fontWeight: "600" }}>
            {Math.round(
              (pieData[i].Nr / (pieData[0].Nr + pieData[1].Nr)) * 100
            )}
            % - {data.Legend}
          </span>
        ))}

        {/* Temp unti api gets fixed */}
        {/* {pieDataPerc.map((perc, i) => (
          <span key={i} style={{ padding: "20px", fontWeight: "600" }}>
            {perc.Nr}% - {perc.Legend}
          </span>
        ))} */}
      </div>
    </div>
  );
};
