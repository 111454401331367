import React, {useState, useEffect} from "react";

// chart imports
import { Chart, ChartSeries, ChartSeriesItem, ChartCategoryAxis, ChartCategoryAxisItem, ChartTitle, ChartLegend } from "@progress/kendo-react-charts";
import 'hammerjs';

// helpers
import { toMonthName } from "../../../utils/helpers";

export const BarGraph = (props) => {

    const [graphColumns, setGraphColumns] = useState([]);
    const [graphXAxis, setGraphXAxis] = useState([]);
    // line toggler
    const [graphLine, setGraphLine] = useState(false);

    const xAxis = [];

    const columns = [
    // Leakage chart [0]
    {
        name: "ALL",
        data: []
    },{
        name: "CLOSED",
        data: []
    }];

    
    let setGraphData = () =>{
        props.graphData.map((dataPoint)=>{
            
            // -------------------------From Leakage To Upsurge-------------------------
            // Leakage Actions Chart
            if(dataPoint.Title == "Leakage Actions"){
                if(dataPoint.Legend == "ALL"){                      // first bar of graph
                    // columns[0].name = "Submitted"
                    columns[0].data.push(dataPoint.Nr)
                    xAxis.push(toMonthName(dataPoint.Month, dataPoint.Year))
                }else{                                              //second bar of graph
                    columns[1].data.push(dataPoint.Nr)
                }
                // rename legend
                columns[0].name = "Submitted"
                columns[1].name = "Closed-out"
                setGraphColumns(columns)
            }

            // Change Tracker Items Chart
            if(dataPoint.Title == "Change Tracker Items"){
                if(dataPoint.Legend == "ALL"){
                    columns[0].data.push(dataPoint.Nr)
                    xAxis.push(toMonthName(dataPoint.Month, dataPoint.Year))
                }else{
                    console.log("Error its not a double bar graph")          
                }
                columns[0].name = "Submitted"
                // slice is used to only get 1 legend "ALL" and not have empty legend space in the graph
                setGraphColumns(columns.slice(0,1)) 
            }

            // Historical Production Added
            if(dataPoint.Title == "Historical Production Added"){

                setGraphLine(true)

                if(dataPoint.Legend == "NR"){
                    columns[0].data.push(dataPoint.Nr)
                    xAxis.push(toMonthName(dataPoint.Month, dataPoint.Year))
                }else{
                    console.log("Error its not a double bar graph")          
                }

                columns[0].name = "Entered Actual"
                // slice is used to only get 1 legend "ALL" and not have empty legend space in the graph
                setGraphColumns(columns.slice(0,1)) 
            }

            // Productions Used In Estimated
            if(dataPoint.Title == "Productions Used In Estimated"){

                // TODO: check name to hardcode unless provided by api
                // columns[0].name = "# of Feedback incidents"

                setGraphLine(true)

                if(dataPoint.Legend == "NR"){
                    columns[0].data.push(dataPoint.Nr)
                    xAxis.push(toMonthName(dataPoint.Month, dataPoint.Year))
                }else{
                    console.log("Error its not a double bar graph")          
                }
                columns[0].name = "Actual"
                // slice is used to only get 1 legend "ALL" and not have empty legend space in the graph
                setGraphColumns(columns.slice(0,1)) 
            }

            // ------------------------------OSCOB Culture-------------------------------
            // Feedback 6 Weeks
            if(dataPoint.Title == "Feedback 6 Weeks"){

                columns[0].name = "# of Feedback incidents"

                if(dataPoint.Legend == "NR"){
                    columns[0].data.push(dataPoint.Nr)
                    // xAxis.push(toMonthName(dataPoint.Month, dataPoint.Year))
                    xAxis.push(`${dataPoint.Week.split("T", 1)[0]}`)
                }else{
                    console.log("Error its not a double bar graph")          
                }

                // slice is used to only get 1 legend "ALL" and not have empty legend space in the graph
                setGraphColumns(columns.slice(0,1)) 
            }

            // OSCOB 6 Weeks
            if(dataPoint.Title == "OSCOB 6 Weeks"){

                // rename the legend
                columns[0].name = "# of Behaviours"

                if(dataPoint.Legend == "NR"){
                    columns[0].data.push(dataPoint.Nr)
                    xAxis.push(`${dataPoint.Week.split("T", 1)[0]}`)
                }else{
                    console.log("Error its not a double bar graph")          
                }

                // slice is used to only get 1 legend "ALL" and not have empty legend space in the graph
                setGraphColumns(columns.slice(0,1)) 
            }

        
            // console.log(columns)
            // console.log(xAxis)

            setGraphXAxis(xAxis)

        })
    }

    useEffect(() => {
        setGraphData()
    }, []);

    return (
        <div className={props.graphData[0].Section == "OSCOB Culture" ? "col-9": "col-6"}>
            <div className="k-card">
                <Chart style={{
                    // height: 350,
                }}>
                    <ChartTitle text={props.graphData[0].Title} />
                    <ChartLegend position="top" />
                    <ChartCategoryAxis>
                        <ChartCategoryAxisItem 
                            categories={graphXAxis} 
                            startAngle={45} 
                            labels={{
                                rotation: "auto",
                            }}
                        />
                    </ChartCategoryAxis>
                    <ChartSeries>
                        {/* {console.log(graphColumns)} */}
                        {/* {console.log(graphXAxis)} */}

                        {/* Line for the bar graph */}
                        {/* {graphLine ?       
                            <ChartSeriesItem type="line" data={[7, 7, 7, 7]} />
                        : 
                        <></>} */}

                        {graphColumns.map((item, idx) => <ChartSeriesItem key={idx} type="column" tooltip={{
                            visible: true
                        }} data={item.data} name={item.name} />)}
                    </ChartSeries>
                </Chart> 
            </div>
        </div>
    )
}
